.wrapper h1 {
  font-family: 'Londrina Solid', serif;
  font-size: 5rem;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 1rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 5rem;
    margin-left: 2rem;
  }

  .bannerSection {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
