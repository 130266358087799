.wrapper {
    display: inline-block;
}

.wrapper h1 {
    font-family: 'Londrina Solid', sans-serif;
    font-size: 68px;
    margin-bottom: 10px;
    margin-top: 40px;
}

.mintWrapper {
    padding-right: 5rem;
    padding-bottom: 0;
    min-height: 520px;
    align-self: flex-end!important;
}

.bidCollection {
    display: grid;
    text-align: start;
    list-style-type: none;
    row-gap: 0.5rem;
    padding: 0;
    margin-bottom: 8px;
    margin-top: 1rem;
    border-bottom: 1px solid var(--brand-cool-border);
}

.bidCollection p {
    font-family: "PT Root UI", sans-serif;
    padding: 0.25rem 0.5rem 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
}
